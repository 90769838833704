import { React, useState } from 'react'
import { Button } from 'react-bootstrap'
import './style.css';
import Modale from '../modal'

export default function Buttons(props) {
    const [modalShow, setModalShow] = useState(false);
    function getTextColor() {
        switch (props.kind) {
            case "blue":
                return "white";
            case "orange":
                return "#ffffff";
            default:
                break;
        }
    }

    function getBackgroundColor() {
        switch (props.kind) {
            case "blue":
                return "#1C53A5";
            case "orange":
                return "#370082"

            default:
                break;
        }
    }

    function getBorderColor() {
        switch (props.kind) {
            case "blue":
                return "#1C53A5";
            case "orange":
                return "#370082"
            default:
                break;
        }
    }
    return (
        <>
            <Button
                style={{
                    backgroundColor: getBackgroundColor(),
                    color: getTextColor(),
                    borderColor: getBorderColor(),
                }}
                className='Buttons'
                onClick={() => setModalShow(true)}
            >
                {props.text}
            </ Button>

            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
                signin={props.signin}
            />
        </>
    )
}
