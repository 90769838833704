import React, { useState } from 'react'
// import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
import { sidebarData } from './sidebarData'
import Modale from '../modal';
import Cookies from 'universal-cookie';



export default function SideBar() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    return (
        <>
            <div className='types'>
                <h4 className='betTypes'>
                    Bet Types
                </h4>
                {sidebarData?.map((data) =>
                    <p key={data.id} onClick={() => setModalShow(true)} style={{ cursor: 'pointer' }}>{data.title}</p>
                )}
            </div>
            {
                cookie.get('token') ?
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    // signin={true}
                    />
                    :
                    <Modale
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        signin={true}
                    />

            }
        </>

    )
}
