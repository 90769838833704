import React from 'react'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Col, Container, Table } from 'react-bootstrap';
import moment from 'moment';
import Modale from '../modal';
import { getResults } from '../../api';
import './style.css'
import shuffledArray from '../../hook/shuffledArray';

export default function CanceledGamesData(props) {
    const [modalShow, setModalShow] = useState(false);
    var shuffledarray = shuffledArray(props.upcoming);

    return (
        <>
            {props.canceled.length !== 0 ? <>
                <Container className='tableContainer'>
                    <h4 style={{ color: '#fff', marginBottom: 16, fontWeight: 600 }}>Canceled Games</h4>
                    <Col className='canceledTableContainer' >
                        <Table responsive="sm"  >
                            <thead>
                                <tr className='tableHead'>
                                    <th>Date</th>
                                    <th>League</th>
                                    <th>Home</th>
                                    <th>Away</th>

                                </tr>
                            </thead>
                            <tbody >
                                {
                                    shuffledarray.filter((data) => data.status === 'canceled')?.map((data, index) => (
                                        <tr key={index} style={{ color: 'white' }}>
                                            <td style={{ fontsize: 14 }}>
                                                {moment().format('d-MMMM-YYYY') + ' at ' + moment().format('hh:mm A')}
                                            </td>
                                            <td style={{ fontSize: 14 }}>
                                                {data.details.league}
                                            </td>
                                            <td>
                                                <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500, margin: 0 }}>{data.teams.home.team}</p>
                                            </td>
                                            <td>
                                                <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 500, margin: 0 }}>{data.teams.away.team}</p>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Container>
                <Modale
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    signin={props.signin}
                />
            </> : null}
        </>
    )
}
