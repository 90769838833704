import React from 'react'
import UserTable from './index'
import { Col } from 'react-bootstrap';
import shuffledArray from '../../hook/shuffledArray';

export default function TableContainer({ upcoming }) {
    const tables = [
        {
            id: 1,
            title: 'BaseBall',
            league: 'MLB'
        },
        {
            id: 2,
            title: 'BasketBall',
            league: 'NBA'
        },
        {
            id: 3,
            title: 'American Football',
            league: 'NFL'
        },
        {
            id: 4,
            title: 'Hockey',
            league: 'NHL'
        },
        {
            id: 5,
            title: 'NCAA Football',
            league: 'NCAAF'
        },
        {
            id: 6,
            title: 'NCAA Basketball',
            league: 'NCAAB'
        },
    ]
    var shuffledarray = shuffledArray(upcoming);


    return (
        <>
            {
                tables?.map((item) => (shuffledarray.filter((data) => data.details.league === item.league).length > 0 &&
                    <Col key={item.id} lg={6} style={{ marginBottom: 16 }}>
                        <UserTable title={item.title} league={item.league} data={shuffledarray.filter((data) => data.details.league === item.league)} />
                    </Col>
                ))

            }
        </>
    )
}
