import { Container } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import NewsData from './newsData';
import './style.css'
import useViewport from "../../hook/useViewport";


export default function News({ news, setNews }) {
    const cookie = new Cookies();
    const { width } = useViewport();


    return (
        <>
            <Container style={{ padding: 0 }}>
                {/* <h4 style={{ color: '#fff', fontWeight: 600 }}>News</h4> */}
                <div className={(width < 993 ? 'mobileNewsContainer' : 'newsContainer')} >
                    <NewsData signin={cookie.get('token') ? null : true} news={news} setNews={setNews} width={width} />
                </div>
                <br></br>
            </Container>
        </>
    )
}
