import React from 'react'
// import axios from 'axios';
import { useState } from 'react'
import moment from 'moment';
import Modale from '../modal';

// import Cookies from 'universal-cookie';

export default function NewsData({ signin, news, setNews, width }) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            {news?.map((data, index) => (
                <div key={index} className={(width < 993 ? 'mobileNewsDetailsContainer' : "newsDetailsContainer")} onClick={() => setModalShow(true)}>
                    <img className={(width < 993 ? 'mobileNewsImage' : 'newsImage')} src={data.enclosures[0].url} alt="news" />
                    <p className="newsTitle" style={{ fontSize: 12, lineHeight: 1.3, height: '4rem' }}>{data.title}</p>
                    <p className='newsDate' >{moment(data.published).format('L HH:MM')}</p>
                    <p className='newsCat' >{data.category}</p>
                </div>
            ))
            }
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={signin}
            />
        </>
    )
}
