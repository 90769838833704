import React from 'react'
import { Container } from 'react-bootstrap'
import Cookies from 'universal-cookie';
import CanceledGamesData from './canceledgamesData';


export default function CanceledGames({ canceled, upcoming }) {
    const cookie = new Cookies();


    return (
        <>

            <div style={{ display: 'flex' }} >
                <CanceledGamesData signin={cookie.get('token') ? null : true} canceled={canceled} upcoming={upcoming} />
            </div>
            <br></br>
        </>
    )
}
