import React from 'react'
import { useState } from 'react'
import { Col, Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import Modale from '../modal';
import './style.css'
import shuffledArray from '../../hook/shuffledArray';
// import Cookies from 'universal-cookie';

export default function UpcomingData(props) {
    const [modalShow, setModalShow] = useState(false);
    var shuffledarray = shuffledArray(props.upcoming);

    return (
        <>

            <Col className='upcomingTableContainer' >
                <Table responsive="sm" >
                    <thead>
                        <tr className='tableHead'>
                            <th>Date</th>
                            <th>League</th>
                            <th>Home</th>
                            <th>Away</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            shuffledarray.filter((data) => data.status !== 'canceled').map((data, index) => (
                                <tr key={index} style={{ color: '#fff' }}>
                                    <td style={{ fontSize: 14 }}>
                                        {/* {moment(data.lastUpdated).tz("USA/California").format('DD-MM-YYYY')} */}
                                        {moment().format('dddd DD MMMM yyyy HH:MM')}
                                    </td>
                                    <td style={{ fontSize: 14 }}>
                                        {data.details.league}
                                    </td>
                                    <td>
                                        <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 400, margin: 0 }}>{data.teams.home.team}</p>
                                    </td>
                                    <td>
                                        <p style={{ fontSize: 14, lineHeight: 1.3, fontWeight: 400, margin: 0 }}>{data.teams.away.team}</p>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Col>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={props.signin}
            />
        </>
    )
}
