import { Container, Row, Col } from 'react-bootstrap';
import './App.css';
import Navigation from './components/navigation'
import Slideshow from './components/slideshow';
import Buttons from './components/button';
import SideBar from './components/sidebar';
// import { useState } from 'react';
import TableContainer from './components/table/tableContainer';
import Cookies from 'universal-cookie';
import Footer from './components/Footer';
import News from './components/news';
import Upcoming from './components/upcoming';
import CanceledGames from './components/canceledgames';
import { useEffect, useState } from 'react';
import Modale from './components/modal';
import axios from 'axios';
import { getResults, getNews } from '../src/api'
import useViewport from "../src/hook/useViewport";


function App() {
  const cookie = new Cookies();
  const [modalShow, setModalShow] = useState(true);
  const [news, setNews] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const { width } = useViewport();

  useEffect(() => {
    axios.get('/api/news')
      .then(response => {
        setNews(response.data.items);
      })
      .catch(error => console.log(error));

    getResults().then(async response => {
      if (response.success) {
        await setUpcoming(response.data[0].results)
        await setCanceled(upcoming.filter((data) => data.status === 'canceled'))

      }
    })
      .catch(error => console.log(error));


  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation />
      <Row>
        <Col>
          <Slideshow />
        </Col>
      </Row>
      {cookie.get('token') ?
        <Modale
          show={modalShow}
          onHide={() => setModalShow(false)}
        // signin={props.signin}
        />
        :
        null
      }
      <br></br>
      <Container>
        <Row>
          <Col lg={2} md={12} >
            <News news={news} setNews={setNews} />
          </Col>
          <Col lg={8} md={8} sm={8}>
            <Upcoming upcoming={upcoming} />
            <CanceledGames canceled={canceled} upcoming={upcoming} />
            <Row>
              <TableContainer upcoming={upcoming} />
            </Row>
          </Col>
          <Col lg={2} md={4} sm={4}>
            <SideBar />
          </Col>
        </Row>
      </Container>
      <br></br>
      <Row>
        <Footer />
      </Row>

    </div >
  );
}

export default App;
