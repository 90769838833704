import React, { useEffect, useState } from 'react'
import { Button, Container, FloatingLabel, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { login, getCusotmers } from '../../../api'
import Cookies from 'universal-cookie'
import LoginModal from '../../loginmodal';

export default function SignIn() {
    const [errorMessage, setErrorMessage] = useState()
    const [logInMessage, setLogInMessage] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const [users, setUsers] = useState([]);
    const cookie = new Cookies()


    const getUsers = () => {
        getCusotmers().then(response => {
            setUsers(response.data)
        })
    }

    useEffect(async () => {
        getUsers()
    }, [])

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            const func = login(username, password)
            func.then(async (response) => {
                if (response.success) {
                    // setLogInMessage('Your account is suspended, Please contact customer support');
                    // alert(response.data.message);
                    // setModalShow(true);
                    cookie.set('token', response.token, { path: '/' })
                    cookie.set('firstname', response.data.firstName, { path: '/' })
                    cookie.set('username', response.data.username, { path: '/' })
                    cookie.set('id', response.data._id, { path: '/' })
                    cookie.set('balance', response.data.balance, { path: '/' })
                    cookie.set('currency', response.data.currency, { path: '/' })
                    window.location.reload()
                } else {
                    setErrorMessage(response.message)
                }
            })
        }
    })


    return (
        <Container >
            <p style={{ color: 'red' }}>
                {errorMessage}
            </p>
            <p style={{ color: 'red' }}>
                <a>{logInMessage}</a>
            </p>
            <Form onSubmit={formik.handleSubmit} >
                <Form.Label><h5 style={{ marginBottom: 0, marginTop: 16 }}>Username</h5></Form.Label>
                <Form.Control
                    className='textInput'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    type="text"
                    name="username" />

                <Form.Label><h5 style={{ margin: 0 }}>Password</h5></Form.Label>
                <Form.Control
                    className='textInput'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    type="password"
                    name="password" />
                <div className='ModalFooterContainer' >
                    <Button type="submit" className='ModalFooterSubmit' >
                        Sign in
                    </Button>
                    <p className='forgotPassword' >Forgot Password?</p>
                </div>
            </Form>
            <LoginModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Container>
    )
}
