// import { Popover } from 'bootstrap';
import React, { useState } from 'react'
import { Nav, Navbar, Container, Dropdown } from 'react-bootstrap'
import './style.css';
import DropDown from '../dropdown';
import Buttons from '../button';
import Cookies from 'universal-cookie';
import Modale from '../modal';
import { navLinks } from './navLinks';
import useViewport from "../../hook/useViewport";
import SigninText from '../signinText'

export default function Navigation() {
    const cookie = new Cookies();
    const [modalShow, setModalShow] = useState(false);
    const { width } = useViewport();
    //className={(width < 600 ? 'mobileNewsContainer' : 'newsContainer')}

    return (
        <Container className="navContainer" fluid >
            <Container className={(width < 767 ? null : 'navWrapper')}>
                <Navbar expand="md" style={{ padding: 0, }}>
                    <Navbar.Brand href="/">
                        <div className="logo"></div>
                    </Navbar.Brand>
                    <Navbar.Toggle className="hamburgerButton" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="navbarScroll">
                        {/* <Nav className="me-auto" ></Nav> */}
                        <Nav
                            className=" my-2 my-lg-0 linkContainer"
                        >
                            <Nav.Link className='navLink2' style={{ marginLeft: 32 }} onClick={() => setModalShow(true)}>Home</Nav.Link>
                            <Nav.Link className='navLink2' onClick={() => setModalShow(true)}>Bet Now</Nav.Link>
                            <Dropdown className='sportDropdownContainer' >
                                <Dropdown.Toggle className="sportDropdown" id="dropdown-basic" style={{ backgroundColor: "transparent" }}>
                                    Sports
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropDownMenu'  >
                                    {navLinks?.map((data) =>
                                        <Dropdown.Item key={data.id} className={data.cName} onClick={() => setModalShow(true)}>{data.link}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Nav.Link className='navLink2' onClick={() => setModalShow(true)}>Upcoming games</Nav.Link>
                            <Nav.Link className='navLink22' onClick={() => setModalShow(true)} >contact us</Nav.Link>


                        </Nav>
                        {
                            cookie.get('token') ?
                                <>
                                    <DropDown />
                                    <Modale
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    // signin={true}
                                    />
                                </>
                                :
                                <>
                                    <SigninText signin={true} />
                                    {/* <Buttons text='sign in' kind="orange" signin={true} /> */}
                                    <Buttons text='sign up' kind="orange" signin={false} />
                                    <Modale
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        signin={true}
                                    />

                                </>

                        }
                    </Navbar.Collapse>
                </Navbar >
            </Container>
        </Container >
    )
}
