import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';


import Modale from '../modal';
import Cookies from 'universal-cookie';
import './style.css'

export default function Footer() {
    const [modalShow, setModalShow] = useState(false);
    const cookie = new Cookies();

    const helpSupport = [
        {
            id: 1,
            title: 'Basket Ball',
        },
        {
            id: 2,
            title: 'Base Ball',
        },
        // {
        //     id: 3,
        //     title: 'Privacy Policy',
        // },
        // {
        //     id: 4,
        //     title: 'Base Ball',
        // },
        // {
        //     id: 5,
        //     title: 'Basket Ball',
        // },
        // {
        //     id: 6,
        //     title: 'Foot Ball',
        // },
        // {
        //     id: 7,
        //     title: 'FAQ',
        // },


    ];

    const links = [
        {
            id: 1,
            title: 'FootBall'
        },
        {
            id: 2,
            title: 'Rugby'
        },
        // {
        //     id: 3,
        //     title: 'Upcoming Games'
        // },
        // {
        //     id: 4,
        //     title: 'Contact Us'
        // },
        // {
        //     id: 5,
        //     title: 'Golf'
        // },
        // {
        //     id: 6,
        //     title: 'Tennis'
        // },
        // {
        //     id: 7,
        //     title: 'Rugby'
        // },

    ]

    return (
        <Container style={{ backgroundColor: '#ffffff', height: 'auto', }}>
            <Container>
                <Row className="linksContainer" sm={3} md={3} xs={2} >
                    <Col lg={2} style={{ textAlign: 'left' }}>
                        <Row>
                            <div className="logo2"></div>
                        </Row>
                        {/* <Row style={{ marginTop: 16, marginBottom: 24 }}>
                            <Col style={{ padding: 0 }}>
                                <FaFacebookF className="SocialMediaIcons " size={25} onClick={() => setModalShow(true)} />
                                <AiFillInstagram className="SocialMediaIcons " size={30} onClick={() => setModalShow(true)} />
                                <FaTwitter className="SocialMediaIcons " size={25} onClick={() => setModalShow(true)} />
                            </Col>
                        </Row> */}
                    </Col>
                    <Col lg={3} xs={12} style={{ textAlign: 'left' }}>
                        {
                            helpSupport?.map((item) => (
                                <p key={item.id} style={{ cursor: 'pointer', marginTop: 8 }} onClick={() => setModalShow(true)}>{item.title}</p>
                            ))
                        }
                    </Col>

                    <Col lg={3} xs={12} style={{ textAlign: 'left' }}>
                        {links?.map((item) => (
                            <p key={item.id} style={{ cursor: 'pointer', marginTop: 8 }} onClick={() => setModalShow(true)}>{item.title}</p>
                        ))
                        }
                    </Col>
                    <Col lg={4}>
                        <p style={{ fontSize: 14, textAlign: 'left', marginTop: 8 }}>
                            18+ Know When To Stop. Don’t Go Over The Top. Gamble Responsibly. Think! About Your Choices. Check Gambling Help on www.gamblinghelponline.org.au
                        </p>
                    </Col>
                </Row>

            </Container>
            <div className="copyRight" >
                <p style={{ margin: 0, paddingTop: 0 }}>Copyright © 2022 - All right reserved.</p>
            </div>
            <Modale
                show={modalShow}
                onHide={() => setModalShow(false)}
                signin={cookie.get('token') ? null : true}
            />
        </Container>
    )
}



