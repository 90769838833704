import React from 'react'
import { Container } from 'react-bootstrap'
import Cookies from 'universal-cookie';
import UpcomingData from './upcomingData';


export default function Upcoming({ upcoming, setUpcoming }) {
    const cookie = new Cookies();

    return (
        <>
            <Container className='tableContainer'>
                <h4 style={{ color: '#fff', marginBottom: 16, fontWeight: 600, marginTop: 16 }}>Upcoming Games</h4>
                <div style={{ display: 'flex' }} >
                    <UpcomingData signin={cookie.get('token') ? null : true} upcoming={upcoming} />
                </div>

                <br></br>
            </Container>
        </>
    )
}
